import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class RolesService {
    constructor(public _commonService: CommonService) { }
    getRoles() {
      return this._commonService.get(urlConstant.Roles.GetList)
        .pipe(map((x: Response) => x));
    }
    rolesInsert(data: any){
        return this._commonService.post(urlConstant.Roles.Create, JSON.stringify(data))
        .pipe(map((x: Response) => x));
    }    
    deleteRoles(id) {
      return this._commonService.get(urlConstant.Roles.Delete + "?id=" + id)
        .pipe(map((x: Response) => x));
    }

    privilegesInsert(data: any){
      return this._commonService.post(urlConstant.RolesPrivileges.Create, JSON.stringify(data))
      .pipe(map((x: Response) => x));
    }
    
    getprivilegesByRoleId(id){
      return this._commonService.get(urlConstant.RolesPrivileges.GetList + "?roleId=" + id)
      .pipe(map((x: Response) => x));
    }
  }